/**
 * Pad to the next alignment boundary
 * @param {Uint8Array} data Uint8Array data to pad
 * @param {number} alignment Alignment boundary to fulfill
 * @param {number} padCharacter Character to fill with
 * @returns {Uint8Array} Padded UInt8Array image
 */
export function padTo(data, alignment, padCharacter = 0xff) {
    const padMod = data.length % alignment;
    if (padMod !== 0) {
        const padding = new Uint8Array(alignment - padMod).fill(padCharacter);
        const paddedData = new Uint8Array(data.length + padding.length);
        paddedData.set(data);
        paddedData.set(padding, data.length);
        return paddedData;
    }
    return data;
}
